import * as React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Box, Button, CardHeader } from "@mui/material";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { appConstants } from "../_constants/app.constans";

export default function Tournaments() {
  const [tournaments, setTournaments] = useState([]);
  

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const res = await axios.get(`${appConstants.API_URL}/api/tournaments`);
        setTournaments(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchTournaments();
  }, []);

  return (
    <React.Fragment>
      <Box
        m={1} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid container spacing={3}>
          {tournaments.map((tournament, index) => {
            if (tournament.isFinished === false) {
              return (
                <Grid item xs={12} sm={3}>
                  <Card variant="outlined">
                    <CardHeader
                      className="lightColorGreen"
                      title={tournament.name}
                      style={{ textAlign: "center" }}
                    />
                    <CardContent style={{ minHeight: "100px" }}>
                      {tournament.description}
                    </CardContent>
                    <CardActions style={{ flexDirection: "column" }}>
                      <Button
                        variant="contained"
                        color="success"
                        href={`/tournamentactions/${tournament.id}`}
                      >
                        Ver torneo
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    </React.Fragment>
  );
}
