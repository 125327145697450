import { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, MenuItem, Grid, Button } from '@mui/material';
import {appConstants} from "./_constants/app.constans";

function App() {
 const [tournaments, setTournaments] = useState([]);
 const [selectedTournamentId, setSelectedTournamentId] = useState('');

 useEffect(() => {
 const fetchTournaments = async () => {
 try {
 const res = await axios.get(`${appConstants.API_URL}/api/tournaments`);
 setTournaments(res.data);
 } catch (err) {
 console.error(err);
 }
 };
 fetchTournaments();
 }, []);

 return (
 <>
 <h2>Selector de torneos</h2>
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
      <Grid item xs={12}>
        <Select
          labelId="tournament-label"
          id="tournament"
          value={selectedTournamentId}
          onChange={(e) => setSelectedTournamentId(e.target.value)}
          >
          <MenuItem value="">
          <em>--Please choose an option--</em>
          </MenuItem>
          {tournaments.map((tournament) => (
          <MenuItem key={tournament.id} value={tournament.id}>
          {tournament.name}
          </MenuItem>
          ))}
          </Select>
      </Grid>
      <Grid item xs={12}>
      <Button href={`/addtournament`}>Añadir torneo</Button>
        <Button href={`/addteam/${selectedTournamentId}`}>Añadir equipos</Button>
        <Button href={`/addgroups/${selectedTournamentId}`}>Añadir grupos</Button>
        <Button href={`/addgrouppositions/${selectedTournamentId}`}>Añadir posiciones</Button>
        <Button href={`/groups/${selectedTournamentId}`}>Ver grupos</Button>
        <Button href={`/addmatchs/${selectedTournamentId}`}>Añadir partidos</Button>
        <Button href={`/addfinalmatchs/${selectedTournamentId}`}>Añadir partidos fase final</Button>
      </Grid>
      <Grid item xs={12}>
        <Button href={`/adminmatches/${selectedTournamentId}`}>Administrar Partidos</Button>
        <Button href={`/adminfinalmatches/${selectedTournamentId}`}>Administrar Partidos Fase Final</Button>
        <Button href={`/ordertie/${selectedTournamentId}`}>Editar orden grupos</Button>
        <Button href={`/matches/${selectedTournamentId}`}>Ver Partidos</Button>
        <Button href={`/viewgroups/${selectedTournamentId}`}>Ver grupos pantalla</Button>
        <Button href={`/viewbracket/${selectedTournamentId}`}>Ver eliminatorias</Button>
        <Button href={`/viewer/${selectedTournamentId}`}>Visor de torneo</Button>
      </Grid>
    </Grid>
 
 </>
 );
}

export default App;
