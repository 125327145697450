import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import EmojiEvents from "@mui/icons-material/EmojiEvents";
import LogoCasti from "../_images/logocasti.png";
import LogoCoruxo from "../_images/coruxo.jpg";
import axios from "axios";
import { useState, useEffect } from "react";
import { appConstants } from "../_constants/app.constans";

export default function TournamentActions() {
  const [tournament, setTournament] = useState({});
  const { tournamentId } = useParams();
  useEffect(() => {
    const fetchTournament = async () => {
      try {
        const res = await axios.get(
          `${appConstants.API_URL}api/tournaments/${tournamentId}`
        );
        setTournament(res.data);
        console.log(res.data);
      } catch (err) {
        console.error(err);
      } 
    };

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${appConstants.API_URL}api/tournaments/TournamentImages/${tournamentId}`
        );
        localStorage.setItem(tournamentId + "-images", JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      }
    };

    const sessionData = localStorage.getItem(tournamentId + "-images");
    if (!sessionData) {
      fetchData();
    }
    fetchTournament();
  }, []);

  return (
    <React.Fragment>
      <Box
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={"10vh"}
      >
        <img src={LogoCasti} style={{ marginRight: "20px" }} />
        <div>
          <label style={{ fontSize: "20px" }}>
            <b>XVI TORNEO TALLERES CASTIÑEIRA</b>
          </label>
          <br />
          <label style={{ fontSize: "12px" }}>
            {tournament && tournament.description}
          </label>
        </div>
        <img src={LogoCoruxo} style={{ marginLeft: "20px" }} />
      </Box>
      <Box
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        minHeight={"90vh"}
      >
        <Button
          style={{ minWidth: "250px", margin: "20px" }}
          variant="contained"
          color="success"
          href={`/viewgroups/${tournamentId}`}
          startIcon={<EmojiEvents fontSize="inherit" />}
          sx={{ fontSize: 24 }}
        >
          Ver grupos
        </Button>
        <Button
          style={{ minWidth: "250px", margin: "20px" }}
          variant="contained"
          color="success"
          href={`/matches/${tournamentId}`}
          startIcon={<EmojiEvents />}
          sx={{ fontSize: 24 }}
        >
          Ver partidos
        </Button>
        <Button
          style={{ minWidth: "250px", margin: "20px" }}
          variant="contained"
          color="success"
          href={`/viewbracket/${tournamentId}`}
          startIcon={<EmojiEvents />}
          sx={{ fontSize: 24 }}
        >
          Ver fase final
        </Button>
      </Box>
    </React.Fragment>
  );
}
