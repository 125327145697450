import * as React from 'react';
import {
    useParams,
  } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Bracket,BracketGenerator,Game } from 'react-tournament-bracket';
import {appConstants} from "../_constants/app.constans";
import './TournamentBracket.css';

export default function ViewBracket (trId){
    const [brackets, setBracketMatchs] = useState([]);
    const {tournamentId} = useParams();
    
    useEffect(() => {
        const fetchBracketMatchs2 = async () => {
        try {
            const res = await axios.get(`${appConstants.API_URL}/api/tournamentmatches/${tournamentId ?? trId}/bracketmatchs/component2`);
            setBracketMatchs(res.data);
            console.log(res.data)
        } catch (err) {
            console.error(err);
        }
        };
        fetchBracketMatchs2();

        const interval = setInterval(fetchBracketMatchs2, 20000);

        return () => clearInterval(interval);
    }, []);

    return (<>
            {brackets.length > 0 &&
                brackets.map((bracket) => {
                    return(
                    <Bracket   topText={({ scheduled }) => new Date(scheduled).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} game={bracket} />
                    );
                })
            }
         </>
      );
};