import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Matches from './Matchs/Matches';
import ViewGroups from './Groups/ViewGroups';
import ViewBracket from './Bracket/ViewBracket';
import Viewer from './Viewer';
import Tournaments from './Tournament/Tournaments';
import TournamentActions from './Tournament/TournamentActions';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Tournaments />,
  },
  {
    path: "/tournamentactions/:tournamentId",
    element: <TournamentActions />,
  },
  {
    path: "/viewgroups/:tournamentId",
    element: <ViewGroups />,
  },
  {
    path: "/matches/:tournamentId",
    element: <Matches />,
  },
  {
    path: "/viewbracket/:tournamentId",
    element: <ViewBracket />,
  }, 
  {
    path: "/viewer/:tournamentId",
    element: <Viewer />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
