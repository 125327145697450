import React, { useState, useEffect } from 'react';
import ViewGroups from './Groups/ViewGroups';
import Matches from './Matchs/Matches';
import ViewBracket from './Bracket/ViewBracket';
import {
    useParams,
  } from "react-router-dom";

const Viewer = () => {
  const [currentComponent, setCurrentComponent] = useState(0);
  const {tournamentId} = useParams();
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentComponent((prevComponent) => (prevComponent + 1) % 3);
    }, 30000); // 30 segundos

    return () => {
      clearInterval(timer);
    };
  }, []);

  const renderComponent = () => {
    switch (currentComponent) {
      case 0:
        return <ViewGroups trId={tournamentId}/>;
      case 1:
        return <Matches />;
      case 2:
        return <ViewBracket />;
      default:
        return null;
    }
  };

  return (
    <div className="screen">
      {/* Aplica estilos de transición suave */}
      <div className="transition">
        {renderComponent()}
      </div>
    </div>
  );
};

export default Viewer;